import * as React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../components/layout/Layout';
import RealisationCard from '../../components/card/Realisation';
import PaginationBlock from '../../components/block/Pagination';

// Realisations
const RealisationsPage = ({ pageContext, data }) => {
  const { totalCount } = data.realisations
  const pageTitle = `Réalisations - ${totalCount} réalisation${totalCount === 1 ? "" : "s"}`

  const headerBackgroundImageRandom = Math.floor(Math.random() * data.realisations.nodes.length);
  const headerBackgroundImage = data.realisations.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.realisations.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`${pageTitle} | ${data.site.siteMetadata.title} - Un cercle de qualité`}
      pageDescription={`Découvrez toutes les réalisations de ${data.site.siteMetadata.title} à Roanne et dans ses environs: plâtrerie peinture, carrelage et faïence, sols souples, décoration, aménagement, façade, isolation thermique par l'intérieur.`}
      pageKeywords="moussé gava, réalisations, réalisation, mably, riorges, plâtrerie, peinture, carrelage, faience, sols stratifiés, décoration, aménagement, façade, papier peints, isolation"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Nos réalisations</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Réalisations</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {data.realisations && data.realisations.nodes.length ?
        <div className="wpo-project-area-s2 section-padding">
          <div className="container">
            <div className="row align-items-center">
              <div className="wpo-project-wrap">
                <div className="sortable-gallery">
                  <div className="gallery-filters"></div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="project-grids gallery-container clearfix">
                        {data.realisations.nodes.map(realisation => (
                          <RealisationCard key={realisation.id} data={realisation} cardStyle="2" />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <PaginationBlock
                path={`realisations`}
                currentPage={pageContext.currentPage}
                numPages={pageContext.numPages}
              />
            </div>
          </div>
        </div>
      : null}
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {  
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    realisations: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          description
          type
          location
          surface
          budget
          duration 
          image {
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default RealisationsPage