import React from 'react';
import { Link } from 'gatsby';

const PaginationBlock = ({ path, numPages, currentPage }) => {

  if (!numPages || !currentPage) return null;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prev = currentPage === 2 ? "/" : `/${path}/${currentPage - 1}`;
  const next = currentPage + 1;

  const start = 1;
  const nums = Array.from({ length: numPages }).map((_, i) => i + start);

  return (
    <div>
      {numPages > 1 &&
        <div className="pagination-wrapper pagination-wrapper-center">
          <ul className="pg-pagination">
            {!isFirst && (
              <li key="previous">
                <Link to={prev} aria-label="Previous" rel="prev">
                  <i className="bi bi-chevron-left"></i>
                </Link>
              </li>
            )}
            {nums.map((num) => (
              <li key={num} className={num === currentPage ? "active" : null}>
                <Link to={num === 1 ? `/${path}/` : `/${path}/${num}/`}>{num}</Link>
              </li>
            ))}
            {!isLast && (
              <li key="next">
                <Link to={`/${path}/${next}/`} aria-label="Next" rel="next">
                  <i className="bi bi-chevron-right"></i>
                </Link>
              </li>
            )}
          </ul>
        </div>
      }
    </div>
  );
}

export default PaginationBlock;
