import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const RealisationCard = ({ data, cardStyle, cardClassName }) => {
  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName}>
          <Link to={`/realisation/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="wpo-project-item">
              <div className="wpo-project-img">
                <GatsbyImage
                  image={getImage(data.frontmatter.image.cover)}
                  alt={data.frontmatter.image.alt}
                />                
                <div className="left-border"></div>
                <div className="right-border"></div>
              </div>
              <div className="wpo-project-text">
                {data.frontmatter.title &&
                  <h4>
                    <Link to={`/realisation/${data.slug}`} aria-label={data.frontmatter.image.alt}>
                      {data.frontmatter.title}
                    </Link>
                  </h4>
                }
                {data.frontmatter.type &&
                  <span>{data.frontmatter.type}</span>
                }
              </div>
            </div>
          </Link>
        </div>
      }  
      {cardStyle === "2" &&
        <div key={data.id} className="grid" data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50">
          <Link to={`/realisation/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="wpo-project-item">
              <div className="wpo-project-img">
                <GatsbyImage
                  image={getImage(data.frontmatter.image.card)}
                  alt={data.frontmatter.image.alt}
                />                
                <div className="left-border"></div>
                <div className="right-border"></div>
              </div>
              <div className="wpo-project-text">
                {data.frontmatter.title &&
                  <h4>
                    <Link to={`/realisation/${data.slug}`} aria-label={data.frontmatter.image.alt}>
                      {data.frontmatter.title}
                    </Link>
                  </h4>
                }
                {data.frontmatter.type &&
                  <span>{data.frontmatter.type}</span>
                }
              </div>
            </div>
          </Link>
        </div>
      }
    </>
  )
}

export default RealisationCard